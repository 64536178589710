import React from "react"
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';
import {logEvent} from "../analytics";


export default class ExpirySelector extends React.Component {
    state = {
        selectedExpiry: "",
        expiryList: [],
        loadingMode: false
    }

    requestExpiryData() {
        const ticker = (this.props.ticker === "") ? "global" : this.props.ticker
        if (ticker==="global") {return;}

        this.setState({loadingMode:true});
        const url = `${process.env.GATSBY_TICKER_API_ENDPOINT}/${ticker}/`;

        return (fetch(url)
                .then(res => res.json())
                .then((data) => this.setState({ expiryList: data },()=>this.selectExpiry(data[0])))
                .then(()=>this.setState({loadingMode:false}))
                .then(()=>logEvent("requestExpiryData_Success", {url:url}))
                .catch((e)=>logEvent("requestExpiryData_Failed", {e:e})));

    }
    componentDidMount() {
        this.refreshExpiryData()
    }
    componentDidUpdate(prevProps) {
        //if a new ticker has been loaded, refresh the expiry list
        if (prevProps.ticker!==this.props.ticker) {   
            this.refreshExpiryData();
        }
    }
    refreshExpiryData() {
        this.setState({expiryList: []},()=>this.requestExpiryData());
    }
    //select a given expiry
    selectExpiry(expiry) {
        this.setState({selectedExpiry:expiry},()=>this.notifyOfSelectionChange(expiry));
        logEvent("selectExpiry", {expiry:expiry});
    }
    // when the user has selected an expiry from the dropdown
    selectionChanged = event =>  {
        this.selectExpiry(event.target.value)
    }
    notifyOfSelectionChange(expiry) {
        this.props.onSelectedExpiryChange(expiry) //pass it up to container
    }

    render() {
        //if we're in the middle of loading new expiries from the database, show the progress thing
        if (this.state.loadingMode === true) {
            return <div><CircularProgress variant="indeterminate" thickness={1.0} color="primary" /></div>;
        }
        //if we don't have a ticker for some reason, don't display the dropdown at all
        if (this.state.expiryList.length === 0) {
            if (this.props.ticker === "") return <></>;
            
        }

        const menuItemList = [];
        this.state.expiryList.forEach(exp => {
            menuItemList.push(
                    <MenuItem key={exp} value={exp}>{exp}</MenuItem>
                )
        });

        return (
            <TextField select label="expiry" id="optionExpirySelect"
                value={this.state.selectedExpiry}
                onChange={this.selectionChanged}
                variant="outlined"
                helperText="Select option expiration">
                {menuItemList}
            </TextField>
        );

    }

}

